import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import EventList from "../components/EventList";
import ArticleList from "../components/ArticleList";
import IdeaList from "../components/IdeaList/IdeaList";
import PersonList from "../components/PersonList";
import SiteMetadata from "../components/SiteMetadata";
import Layout from "../layouts/Layout";
import makeUrl from "../utils/url";
import HeadingTop from "../components/BuildingBlocks/HeadingTop";

export default function IdeaItem(props) {
  const {
    description,
    title,
    website,
    price,
    person,
    instruction,
    image,
  } = props.data.item;

  return (
    <Layout>
      <SiteMetadata
        title={`${title.title} - Toller Geschenktipp 🤩`}
        description={`Entdecke diese tolle Geschenkidee ${title.title}! Bereite deiner Lieblingsperson eine Freude.`}
        image={`${image[0].file.url}`}
      />
      <HeadingTop />
      <div className="container">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-1/2 p-8 max-w-xl max-h-xl">
            <GatsbyImage
              image={image[0].localFile.childImageSharp.gatsbyImageData}
              className="h-full w-full rounded-md"
              alt={`Bild ${title.title}`}
            />
          </div>
          <div className="w-full lg:w-1/2 p-8 leading-relaxed flex flex-wrap items-start content-start">
            <h4 className="text-3xl lg:text-4xl text-red-800 w-full font-display mb-2">
              {title.title}
            </h4>

            <div className="text-lg">
              <div className="font-display text-xl my-2">Beschreibung</div>
              {description.description.split("#").map((txt, i) => (
                <p key={i}>{txt}</p>
              ))}
            </div>
            {price && (
              <div className="mx-auto mt-4 text-gray-800 text-lg w-full">
                <span className="font-display text-xl">Preis</span> <br /> ca.{" "}
                {price}€
              </div>
            )}
            {website && (
              <a href={website.website} target="__blank">
                <div className="bg-1 text-white py-3 px-5 mt-6 rounded-2xl hover:bg-red-500 transition duration-300">
                  {price && "Hol dir das Geschenk"}
                  {!price && "Hier gibts mehr!"}
                </div>
              </a>
            )}

            {instruction && (
              <Link to={`/${makeUrl(instruction.title, "anleitung")}`}>
                <div className="bg-yellow-500 text-white font-bold py-3 px-5 mt-4 mr-4 rounded-2xl hover:bg-red-500 transition duration-300">
                  Hier gehts zur Anleitung!
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>

      {person && (
        <PersonList items={person} title={"Dieses Geschenk passt für"} />
      )}
      <ArticleList
        person={
          person ? person[0]?.childContentfulPersonTitleTextNode.title : false
        }
      />
      <IdeaList />
      <EventList items={props.data.events.nodes} />
    </Layout>
  );
}

export const query = graphql`
  query IdeaQuery($id: String!) {
    item: contentfulIdee(id: { eq: $id }) {
      description {
        description
      }
      title {
        title
      }
      website {
        website
      }
      price
      person {
        childContentfulPersonTitleTextNode {
          title
        }
        icon {
          gatsbyImageData
        }
      }
      instruction {
        title
      }
      image {
        file {
          url
        }
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    events: allContentfulAnlass(sort: { order: ASC, fields: date }) {
      nodes {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        title
      }
    }
  }
`;
